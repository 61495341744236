import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import cookie from "js-cookie";
import { useHistory } from "react-router-dom";
import Loader from "../../components/shared/Loader";
import { updateMeta } from "../../store/actions/metaAction";
import { useDispatch } from "react-redux";

const ModeratorsCreate = () => {
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [passwordConfirmation, setPasswordConfirmation] = useState("");
	const [loading, setLoading] = useState(false);
	const history = useHistory();
	const dispatch = useDispatch();

	useEffect(() => {
		onStart();
	}, []);

	const onStart = () => {
		dispatch(
			updateMeta({
				title: "اضافة مشرف جديد",
				breadcrumb: "المشرفين",
				button: {
					show: false,
					text: null,
					link: null,
				},
			})
		);
	};

	const handleSubmit = e => {
		e.preventDefault();
		if (password !== passwordConfirmation) {
			toast.error("كلمتي المرور غير متطابقتين!");
		} else {
			setLoading(true);
			axios
				.post(
					`${process.env.REACT_APP_API_URL}/admins`,
					{
						name: name,
						email: email,
						password: password,
						password_confirmation: passwordConfirmation,
					},
					{
						headers: {
							Authorization: `Bearer ${cookie.get("token")}`,
							Accept: "application/json",
						},
					}
				)
				.then(function (response) {
					history.push("/moderators/index");
					toast.success("تم اضافة المشرف بنجاح!");
					setLoading(false);
				})
				.catch(function (error) {
					toast.error("خطأ، برجاء المحاولة مره اخرى!");
					setLoading(false);
				});
		}
	};

	return (
		<section>
			{loading && <Loader />}
			<form onSubmit={handleSubmit}>
				<div className="row">
					<div className="col-md-6">
						<div className="form-item">
							<label htmlFor="name">الاسم</label>
							<input
								id="name"
								type="text"
								onChange={e => setName(e.target.value)}
								required
							/>
						</div>
					</div>
					<div className="col-md-6">
						<div className="form-item">
							<label htmlFor="email">البريد الالكتروني</label>
							<input
								id="email"
								type="email"
								onChange={e => setEmail(e.target.value)}
								required
							/>
						</div>
					</div>
					<div className="col-md-6">
						<div className="form-item">
							<label htmlFor="password">كلمة المرور</label>
							<input
								id="password"
								type="password"
								onChange={e => setPassword(e.target.value)}
								required
							/>
						</div>
					</div>
					<div className="col-md-6">
						<div className="form-item">
							<label htmlFor="passwordConfirmation">اعادة كلمة المرور</label>
							<input
								id="passwordConfirmation"
								type="password"
								onChange={e => setPasswordConfirmation(e.target.value)}
								required
							/>
						</div>
					</div>
				</div>
				<div className="form-item">
					<button className="bt bt-success">ارسال</button>
				</div>
			</form>
		</section>
	);
};

export default ModeratorsCreate;
