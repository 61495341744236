import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "axios";
import cookie from "js-cookie";
import { toast } from "react-toastify";
import Loader from "../../../components/shared/Loader";
import { updateMeta } from "../../../store/actions/metaAction";

const FormCreate = () => {
	const auth = useSelector(state => state.auth.user);
	const history = useHistory();
	const [title, setTitle] = useState("");
	const [titleEn, setTitleEn] = useState("");

	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();

	useEffect(() => {
		onStart();
	}, []);

	const onStart = () => {
		dispatch(
			updateMeta({
				title: "اضافة عنوان",
				breadcrumb: "العنواين",
				button: {
					show: false,
					text: null,
					link: null,
				},
			})
		);
	};

	const handleSubmit = e => {
		e.preventDefault();

		if (!title || !titleEn) {
			return toast.error("جميع البيانات مطلوبة");
		}

		const data = {
			title: {
				ar: title,
				en: titleEn,
			},
		};
		axios
			.post(
				`${process.env.REACT_APP_API_URL}/posts`,
				{
					admin_id: auth.id,
					category_id: 44,
					data: data,
				},
				{
					headers: {
						Authorization: `Bearer ${cookie.get("token")}`,
						Accept: "application/json",
					},
				}
			)
			.then(function (response) {
				history.push("/homePage/form/index");
				toast.success("تم اضافة العنوان بنجاح!");
				setLoading(false);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة مره اخرى!");
				setLoading(false);
			});
	};

	return (
		<section>
			{loading && <Loader />}
			<form onSubmit={handleSubmit}>
				<div className="row">
					<div className="col-md-12">
						<div className="card">
							<div className="card-body">
								<div className="row justify-content-center">
									<div className="col-md-6">
										<div className="form-item">
											<label htmlFor="title">العنوان بالعربي</label>
											<input
												type="text"
												id="title"
												onChange={e => setTitle(e.target.value)}
											/>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-item">
											<label htmlFor="title">العنوان بالانجليزي</label>
											<input
												type="text"
												id="title"
												onChange={e => setTitleEn(e.target.value)}
											/>
										</div>
									</div>

									<div className="col-md-4">
										<div className="form-item mt-3">
											<button className="bt bt-success btn-block">ارسال</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		</section>
	);
};

export default FormCreate;
