let id = 1;
const randomId = () => {
	id++;
	return id;
};

export const navbarItems = [
	{
		id: randomId(),
		link: "/",
		icon: "fas fa-home",
		title: "لوحة التحكم",
		children: [],
	},
	{
		id: randomId(),
		link: "/moderators/index",
		icon: "fas fa-user",
		title: "المشرفين",
		children: [
			{
				id: randomId(),
				title: "جميع المشرفين",
				link: "/moderators/index",
			},
			{
				id: randomId(),
				title: "اضافة مشرف جديد",
				link: "/moderators/create",
			},
		],
	},
	// {
	// 	id: randomId(),
	// 	link: "/blog/index",
	// 	icon: "fas fa-edit",
	// 	title: "المدونة",
	// 	children: [
	// 		{
	// 			id: randomId(),
	// 			title: "جميع المقالات",
	// 			link: "/blog/index",
	// 		},
	// 		{
	// 			id: randomId(),
	// 			title: "اضافة مقال جديد",
	// 			link: "/blog/create",
	// 		},
	// 		{
	// 			id: randomId(),
	// 			title: "التصنيفات",
	// 			link: "/categories/index",
	// 		},
	// 	],
	// },
	// {
	// 	id: randomId(),
	// 	link: "/services/index",
	// 	icon: "fas fa-layer-group",
	// 	title: "الخدمات",
	// 	children: [
	// 		{
	// 			id: randomId(),
	// 			title: "جميع الخدمات",
	// 			link: "/services/index",
	// 		},
	// 		{
	// 			id: randomId(),
	// 			title: "اضافة خدمة جديدة",
	// 			link: "/services/create",
	// 		},
	// 		{
	// 			id: randomId(),
	// 			title: "طلبات الخدمات",
	// 			link: "/services/applications",
	// 		},
	// 	],
	// },

	{
		id: randomId(),
		link: "/contact/index",
		icon: "fas fa-envelope",
		title: "اتصل بنا",
		children: [],
	},

	// {
	// 	id: randomId(),
	// 	link: "/footerLinks/index",
	// 	icon: "fas fa-link",
	// 	title: "روابط الفوتر",
	// 	children: [],
	// },

	{
		id: randomId(),
		link: "/homePage",
		icon: "far fa-images",
		title: "الصفحه الرئيسيه",
		children: [
			{
				id: randomId(),
				title: "السلايدر",
				link: "/homePage/slider/index",
			},
			{
				id: randomId(),
				title: " من نحن",
				link: "/homePage/aboutUs/show/",
			},
			{
				id: randomId(),
				title: " المميزات",
				link: "/homePage/advantages/index",
			},
			{
				id: randomId(),
				title: " خدماتنا",
				link: "/homePage/ourServices/index",
			},
			{
				id: randomId(),
				title: " أسعار خدمات السيارات",
				link: "/homePage/carServices/index",
			},
			{
				id: randomId(),
				title: " الأستماره",
				link: "/homePage/form/index",
			},
			{
				id: randomId(),
				title: " الأراء",
				link: "/homePage/testimonial/index",
			},
		],
	},
	{
		id: randomId(),
		link: "/newsletter/index",
		icon: "far fa-envelope",
		title: "القائمة البريدية",
		children: [],
	},
];
