import React, { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import Loader from "../../components/shared/Loader";
import axios from "axios";
import cookie from "js-cookie";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import userPlaceholder from "../../assets/images/placeholder.jpg";
import { updateMeta } from "../../store/actions/metaAction";
import { useDropzone } from "react-dropzone";

const ServiceShow = ({ match }) => {
	const {
		params: { serviceID },
	} = match;

	const auth = useSelector(state => state.auth.user);
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");
	const [pageContent, setPageContent] = useState("");
	const [picture, setPicture] = useState(userPlaceholder);
	const [featureTitle, setFeatureTitle] = useState("");
	const [features, setFeatures] = useState([]);
	const [files, setFiles] = useState([]);
	const [catalog, setCatalog] = useState([]);
	const [selectedCatalog, setSelectedCatalog] = useState([]);
	const [imageArray, setImageArray] = useState([]);
	const dispatch = useDispatch();

	let editor = null;

	useEffect(() => {
		onStart();
	}, []);

	useEffect(
		() => () => {
			files.forEach(file => URL.revokeObjectURL(file.preview));
		},
		[files]
	);

	const onStart = () => {
		dispatch(
			updateMeta({
				title: "تعديل تفاصيل الخدمة",
				breadcrumb: "الخدمات",
				button: {
					show: false,
					text: null,
					link: null,
				},
			})
		);

		axios
			.get(`${process.env.REACT_APP_API_URL}/posts/${serviceID}`, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
				},
			})
			.then(function (response) {
				setLoading(false);
				setTitle(response.data.data.data.title);
				setDescription(response.data.data.data.description);
				setPageContent(response.data.data.data.page_content ?? "");
				setPicture(response.data.data.data.icon);
				setFeatures(response.data.data.data.features ?? []);
				setImageArray(response.data.data.data.images ?? []);
				setSelectedCatalog(response.data.data.data.catalog ?? []);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة لاحقا!");
			});
	};

	const handlePicture = e => {
		setLoading(true);
		const fileInput = document.querySelector("#picture");
		setPicture(URL.createObjectURL(fileInput.files[0]));
		const data = new FormData();
		data.append("file", fileInput.files[0]);
		axios
			.post(`${process.env.REACT_APP_API_URL}/upload`, data, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
					"Content-Type": "multipart/form-data",
				},
			})
			.then(response => {
				setPicture(response.data.data.name);
				setLoading(false);
				toast.success("تم رفع الصورة بنجاح!");
			})
			.catch(error => {
				toast.error("خطأ في رفع الصورة، برجاء المحاولة مرة أخرى!");
				setLoading(false);
			});
	};

	const pushToInformation = e => {
		e.preventDefault();
		if (featureTitle === "") {
			toast.error("برجاء تعبئة الحقول المطلوبة!");
			return;
		}
		const data = { title: featureTitle };
		features.push(data);
		setFeatures(features);
		setFeatureTitle("");
	};

	const handleDeleteFeature = (event, information) => {
		event.preventDefault();
		const arr = features.filter(function (item) {
			return item.title !== information.title;
		});
		setFeatures(arr);
	};

	const { getRootProps, getInputProps } = useDropzone({
		accept: "image/*",
		multiple: false,
		onDrop: acceptedFiles => {
			setFiles(
				acceptedFiles.map(file =>
					Object.assign(file, {
						preview: URL.createObjectURL(file),
					})
				)
			);
		},
	});

	const uploadCatalog = useDropzone({
		accept: ".jpg, .png, .pdf, .doc, .docx, .ppt, .pptx",
		multiple: false,
		onDrop: acceptedFiles => {
			setCatalog(
				acceptedFiles.map(file =>
					Object.assign(file, {
						preview: URL.createObjectURL(file),
					})
				)
			);
		},
	});

	const thumbs = files.map(file => (
		<div className="thumb" key={file.name}>
			<div className="thumbInner">
				<img src={file.preview} className="img" />
			</div>
		</div>
	));

	const catalogPreview = catalog.map(catalog => (
		<div className="catalog my-3">
			<i className="fas fa-file-alt" /> {catalog?.name}
		</div>
	));

	const handleImageUpload = async e => {
		if (!files.length) {
			toast.error("برجاء إختيار صورة اولا");
			return;
		}
		e.preventDefault();
		setLoading(true);
		for (let i = 0; i < files.length; i++) {
			const formData = new FormData();
			formData.append(`file`, files[i]);
			await axios
				.post(`${process.env.REACT_APP_API_URL}/upload`, formData, {
					headers: {
						Authorization: `Bearer ${cookie.get("token")}`,
						Accept: "application/json",
						"Content-Type": "multipart/form-data",
					},
				})
				.then(function (responseImage) {
					setImageArray([responseImage.data.data.name]);
				})
				.catch(function (error) {
					toast.error("خطأ، برجاء المحاولة مره اخرى!");
					setLoading(false);
				});
		}
		setLoading(false);
		toast.success("تم رفع الصور بنجاح!");
	};

	const handleCatalogUpload = async e => {
		if (!catalog.length) {
			toast.error("برجاء إختيار ملف اولا");
			return;
		}
		e.preventDefault();
		setLoading(true);
		for (let i = 0; i < catalog.length; i++) {
			const formData = new FormData();
			formData.append(`file`, catalog[i]);
			await axios
				.post(`${process.env.REACT_APP_API_URL}/upload`, formData, {
					headers: {
						Authorization: `Bearer ${cookie.get("token")}`,
						Accept: "application/json",
						"Content-Type": "multipart/form-data",
					},
				})
				.then(function (responseImage) {
					setSelectedCatalog([responseImage.data.data.name]);
				})
				.catch(function (error) {
					toast.error("خطأ، برجاء المحاولة مره اخرى!");
					setLoading(false);
				});
		}
		setLoading(false);
		toast.success("تم رفع الملف بنجاح!");
	};

	const deleteImage = (event, img) => {
		event.preventDefault();
		const arr = imageArray.filter(item => {
			return item !== img;
		});
		setImageArray(arr);
	};

	const deleteCatalog = (event, catalog) => {
		event.preventDefault();
		const arr = selectedCatalog.filter(item => {
			return item !== catalog;
		});
		setSelectedCatalog(arr);
	};

	const handleSubmit = e => {
		e.preventDefault();
		const data = {
			title: title,
			description: description,
			icon: picture,
			page_content: pageContent,
			features: features,
			images: imageArray,
			catalog: selectedCatalog,
		};
		axios
			.put(
				`${process.env.REACT_APP_API_URL}/posts/${serviceID}`,
				{
					category_id: 3,
					admin_id: auth.id,
					data: data,
				},
				{
					headers: {
						Authorization: `Bearer ${cookie.get("token")}`,
						Accept: "application/json",
					},
				}
			)
			.then(function (response) {
				toast.success("تم تحديث الخدمة بنجاح!");
				setLoading(false);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة مره اخرى!");
				setLoading(false);
			});
	};

	return (
		<section>
			{loading && <Loader />}
			<form onSubmit={handleSubmit}>
				<div className="row">
					<div className="col-md-9">
						<div className="card">
							<div className="card-body">
								<div className="form-item">
									<label htmlFor="title">العنوان</label>
									<input
										type="text"
										id="title"
										required
										value={title}
										onChange={e => setTitle(e.target.value)}
									/>
								</div>
								<div className="form-item">
									<label htmlFor="description">الوصف</label>
									<input
										type="text"
										id="description"
										required
										value={description}
										onChange={e => setDescription(e.target.value)}
									/>
								</div>
								<div className="form-item">
									<label htmlFor="pageContent">النص</label>
									<CKEditor
										data={pageContent}
										onReady={editor => {
											editor.ui
												.getEditableElement()
												.parentElement.insertBefore(
													editor.ui.view.toolbar.element,
													editor.ui.getEditableElement()
												);
											editor = editor;
										}}
										onError={({ willEditorRestart }) => {
											if (willEditorRestart) {
												editor.ui.view.toolbar.element.remove();
											}
										}}
										onChange={(event, editor) => {
											const data = editor.getData();
											setPageContent(data);
										}}
										editor={DecoupledEditor}
										config={{
											contentsLangDirection: "rtl",
											language: "ar",
											extraPlugins: [MyCustomUploadAdapterPlugin],
										}}
									/>
								</div>
								<div className="dropzoneContainer">
									<div {...getRootProps({ className: "dropzone" })}>
										<input {...getInputProps()} />
										<p>اضغط هنا لرفع الصورة الداخلية</p>
									</div>
									<aside className="thumbsContainer">{thumbs}</aside>
									<button
										onClick={handleImageUpload}
										type="button"
										className="bt bt-success"
									>
										رفع الصورة
									</button>
								</div>
								<div className="row">
									{imageArray.map(img => (
										<div className="col-md-2" key={img}>
											<div className="image-preview">
												<img
													src={`${process.env.REACT_APP_MAIN_URL}${img}`}
													alt={img}
													className="imgList"
												/>
												<button onClick={e => deleteImage(e, img)}>
													<i className="fas fa-trash"></i>
												</button>
											</div>
										</div>
									))}
								</div>

								<div className="dropzoneContainer mt-4">
									<div
										{...uploadCatalog.getRootProps({ className: "dropzone" })}
									>
										<input {...uploadCatalog.getInputProps()} />
										<p>اضغط هنا لرفع الكتالوج</p>
									</div>
									<aside className="thumbsContainer">{catalogPreview}</aside>
									<button
										onClick={handleCatalogUpload}
										type="button"
										className="bt bt-success"
									>
										رفع الملف
									</button>
								</div>
								<div className="row">
									{selectedCatalog.map(catalog => (
										<div className="col-md-3" key={catalog}>
											<div className="image-preview">
												<div className="catalog my-3">
													<i className="fas fa-file-alt" />{" "}
													{catalog.split("/").pop()}
												</div>
												<button onClick={e => deleteCatalog(e, catalog)}>
													<i className="fas fa-trash" />
												</button>
											</div>
										</div>
									))}
								</div>
								<br />
								<hr />
								<div className="form-item">
									<label htmlFor="question">عنوان الميزة</label>
									<input
										type="text"
										id="question"
										value={featureTitle}
										onChange={e => setFeatureTitle(e.target.value)}
									/>
								</div>
								<div className="form-item" />
								<button className="bt bt-success" onClick={pushToInformation}>
									اضافة الميزة
								</button>
								<div className="form-item" style={{ marginTop: "40px" }}>
									<ul className="list-unstyled pointList">
										{features.map(feature => (
											<li key={feature.title}>
												<button onClick={e => handleDeleteFeature(e, feature)}>
													<i className="fas fa-trash" />
												</button>
												<span>{feature.title}</span>
											</li>
										))}
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-3">
						<div className="card">
							<div className="card-body">
								<div className="form-item">
									<input type="file" onChange={handlePicture} id="picture" />
									<img
										src={process.env.REACT_APP_MAIN_URL + picture}
										alt="avatar"
										className="imagePreview"
									/>
								</div>
							</div>
						</div>
						<div className="form-item mt-3">
							<button className="bt bt-success btn-block">ارسال</button>
						</div>
					</div>
				</div>
			</form>
		</section>
	);
};

function MyCustomUploadAdapterPlugin(editor) {
	editor.plugins.get("FileRepository").createUploadAdapter = loader => {
		return new MyUploadAdapter(loader);
	};
}

class MyUploadAdapter {
	constructor(props) {
		this.loader = props;
		this.url = `${process.env.REACT_APP_API_URL}/upload`;
	}

	upload() {
		return new Promise((resolve, reject) => {
			this._initRequest();
			this._initListeners(resolve, reject);
			this._sendRequest();
		});
	}

	abort() {
		if (this.xhr) {
			this.xhr.abort();
		}
	}

	_initRequest() {
		const xhr = (this.xhr = new XMLHttpRequest());

		xhr.open("POST", this.url, true);
		xhr.responseType = "json";
		xhr.setRequestHeader("Authorization", `Bearer ${cookie.get("token")}`);
	}

	_initListeners(resolve, reject) {
		const xhr = this.xhr;
		const loader = this.loader;
		const genericErrorText = "Couldn't upload file:" + ` ${loader.file.name}.`;

		xhr.addEventListener("error", () => reject(genericErrorText));
		xhr.addEventListener("abort", () => reject());
		xhr.addEventListener("load", () => {
			const response = xhr.response;
			if (!response || response.error) {
				return reject(
					response && response.error ? response.error.message : genericErrorText
				);
			}
			resolve({
				default: response.data.filePath,
			});
		});

		if (xhr.upload) {
			xhr.upload.addEventListener("progress", evt => {
				if (evt.lengthComputable) {
					loader.uploadTotal = evt.total;
					loader.uploaded = evt.loaded;
				}
			});
		}
	}

	_sendRequest() {
		const data = new FormData();
		this.loader.file.then(result => {
			data.append("file", result);
			this.xhr.send(data);
		});
	}
}

export default ServiceShow;
