import NavItem from "./NavItem";
import { navbarItems } from "../../helpers/data/navbar";
import { useDispatch } from "react-redux";

import settingsIcon from "../../assets/images/icons/settings.svg";
import logoutIcon from "../../assets/images/icons/logout.svg";
import { Link, useHistory } from "react-router-dom";
import cookie from "js-cookie";
import { toast } from "react-toastify";
import { logout } from "../../store/actions/userActions";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";

const Sidebar = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const toggleResponsiveMenu = () => {
		const sidebar = document.getElementById("sidebar");
		sidebar.classList.toggle("sidebar-responsive");
		const sidebarWrapper = document.getElementById("sm-wrapper");
		sidebarWrapper.classList.toggle("sm-wrapper-toggle");
	};

	const handleLogout = e => {
		e.preventDefault();
		dispatch(logout());
		cookie.remove("token");
		history.push("/login");
		toast.success("تم تسجيل خروجك بنجاح!");
	};

	const [settings, setSettings] = useState([]);

	useEffect(() => {
		onStart();
	}, []);

	const onStart = () => {
		axios
			.get(`https://alnaasielaundry.ejjadh.info/api/settings/main`, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
				},
			})
			.then(function (response) {
				setSettings(response.data.data);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة لاحقا!");
			});
	};

	return (
		<>
			<section className="sidebar" id="sidebar">
				<div className="logo">
					<a href="/" target="_blank" rel="noreferrer">
						<img
							src={
								settings.length > 0
									? `${process.env.REACT_APP_MAIN_URL}${settings[1].value}`
									: ""
							}
							alt="logo"
						/>
					</a>
				</div>
				<ul className="sidebar-menu list-inline text-center">
					<li className="list-inline-item">
						<Link to="/settings">
							<img src={settingsIcon} alt="settings" />
						</Link>
					</li>
					<li className="list-inline-item">
						<a onClick={handleLogout} href="/">
							<img src={logoutIcon} alt="logout" />
						</a>
					</li>
				</ul>
				<div className="accordion" id="sidebar-menu">
					{navbarItems.map(item => (
						<NavItem item={item} key={item.id} />
					))}
				</div>
			</section>
			<section
				className="sm-wrapper d-block d-sm-none"
				id="sm-wrapper"
				onClick={toggleResponsiveMenu}
			></section>
		</>
	);
};

export default Sidebar;
