import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { ToastContainer } from "react-toastify";
import { Provider } from "react-redux";
import { store } from "./store/store";
import cookie from "js-cookie";
import axios from "axios";
import "bootstrap-v4-rtl/dist/css/bootstrap-rtl.min.css";
import "bootstrap-v4-rtl/dist/js/bootstrap.min";
import "jquery";
import "popper.js";
import "react-toastify/dist/ReactToastify.css";
import "../src/styles/global.scss";
import * as types from "../src/store/types";

let token = cookie.get("token");

const render = () => {
	ReactDOM.render(
		<React.StrictMode>
			<Provider store={store}>
				<ToastContainer rtl position="bottom-left" />
				<App />
			</Provider>
		</React.StrictMode>,
		document.getElementById("root")
	);
};

if (token) {
	axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
	axios
		.get(`${process.env.REACT_APP_API_URL}/profile`)
		.then(response => {
			store.dispatch({ type: types.LOG_IN, payload: response.data.data });
			render();
		})
		.catch(e => console.log(e));
} else {
	render();
}
