import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "axios";
import cookie from "js-cookie";
import { toast } from "react-toastify";
import Loader from "../../../components/shared/Loader";
import { updateMeta } from "../../../store/actions/metaAction";

const TestimonialShow = ({ match }) => {
	const {
		params: { testimonialID },
	} = match;

	const auth = useSelector(state => state.auth.user);
	const history = useHistory();

	// const [title, setTitle] = useState("");

	const [image, setImage] = useState("");

	const [formData, setFormData] = useState({});

	const handleInputChange = e => {
		setFormData({
			...formData,
			[e.target.name]: {
				...formData?.[e.target.name],
				...formData?.[e.target.name],
				[e.target.lang]: e.target.value,
			},
		});
	};

	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();

	useEffect(() => {
		onStart();
	}, []);

	const onStart = () => {
		dispatch(
			updateMeta({
				title: "اضافة رأي جديد",
				breadcrumb: "رأي",
				button: {
					show: false,
					text: null,
					link: null,
				},
			})
		);

		axios
			.get(`${process.env.REACT_APP_API_URL}/posts/${testimonialID}`, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
				},
			})
			.then(function (response) {
				setFormData(response?.data?.data?.data);
				setImage(response?.data?.data?.data?.image);

				setLoading(false);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة لاحقا!");
			});
	};

	const handlePicture = () => {
		const fileInput = document.querySelector(`#image`);
		setImage(URL.createObjectURL(fileInput.files[0]));

		const data = new FormData();
		data.append("file", fileInput.files[0]);
		axios
			.post(`${process.env.REACT_APP_API_URL}/upload`, data, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
					"Content-Type": "multipart/form-data",
				},
			})
			.then(response => {
				setImage(response.data.data.name);
				setLoading(false);
				toast.success("تم رفع الصورة بنجاح!");
			})
			.catch(error => {
				toast.error("خطأ في رفع الصورة، برجاء المحاولة مرة أخرى!");
				setLoading(false);
			});
	};

	const handleSubmit = e => {
		e.preventDefault();
		if (
			!formData?.title.ar ||
			!formData?.title.en ||
			!formData?.subtitle.ar ||
			!formData?.subtitle.en ||
			!formData?.description.ar ||
			!formData?.description.en
		) {
			return toast.error("جميع البيانات مطلوبة");
		}

		const data = {
			...formData,
			image: image,
		};

		const picture = document.querySelector("#image");

		if (picture.files[0]) {
			const data = new FormData();
			data.append("file", picture.files[0]);

			axios
				.post(`${process.env.REACT_APP_API_URL}/upload`, data, {
					headers: {
						Authorization: `Bearer ${cookie.get("token")}`,
						Accept: "application/json",
						"Content-Type": "multipart/form-data",
					},
				})
				.then(response => {
					const data = {
						...formData,
						image: response?.data?.data?.name,
					};
					axios
						.put(
							`${process.env.REACT_APP_API_URL}/posts/${testimonialID}`,
							{
								admin_id: auth.id,
								category_id: 45,
								data: data,
							},
							{
								headers: {
									Authorization: `Bearer ${cookie.get("token")}`,
									Accept: "application/json",
								},
							}
						)
						.then(function (response) {
							history.push("/homePage/testimonial/index");
							toast.success("تم اضافة الرأي بنجاح!");
							setLoading(false);
						})
						.catch(function (error) {
							toast.error("خطأ، برجاء المحاولة مره اخرى!");
							setLoading(false);
						});
				});
		}

		axios
			.put(
				`${process.env.REACT_APP_API_URL}/posts/${testimonialID}`,
				{
					admin_id: auth.id,
					category_id: 45,
					data: data,
				},
				{
					headers: {
						Authorization: `Bearer ${cookie.get("token")}`,
						Accept: "application/json",
					},
				}
			)
			.then(function (response) {
				history.push("/homePage/testimonial/index");
				toast.success("تم اضافة الرأي بنجاح!");
				setLoading(false);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة مره اخرى!");
				setLoading(false);
			});
	};

	return (
		<section>
			{loading && <Loader />}
			<form onSubmit={handleSubmit}>
				<div className="row">
					<div className="col-md-8">
						<div className="card">
							<div className="card-body">
								<div className="row justify-content-center">
									<div className="col-md-6">
										<div className="form-item">
											<label htmlFor="title">الاسم بالعربي</label>
											<input
												type="text"
												name="title"
												lang="ar"
												value={formData?.title?.ar}
												onChange={handleInputChange}
											/>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-item">
											<label htmlFor="title">الاسم بالانجليزي</label>
											<input
												type="text"
												name="title"
												lang="en"
												value={formData?.title?.en}
												onChange={handleInputChange}
											/>
										</div>
									</div>

									<div className="col-md-6">
										<div className="form-item">
											<label htmlFor="subtitle">الخدمه بالعربي</label>
											<input
												lang="ar"
												type="text"
												name="subtitle"
												value={formData?.subtitle?.ar}
												onChange={handleInputChange}
											/>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-item">
											<label htmlFor="subtitle">الخدمه بالانجليزي</label>
											<input
												lang="en"
												type="text"
												name="subtitle"
												value={formData?.subtitle?.en}
												onChange={handleInputChange}
											/>
										</div>
									</div>

									<div className="col-md-12">
										<div className="form-item">
											<label htmlFor="title">الوصف بالعربي</label>
											<input
												lang="ar"
												type="text"
												name="description"
												value={formData?.description?.ar}
												onChange={handleInputChange}
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-item">
											<label htmlFor="title">الوصف بالانجليزي</label>
											<input
												lang="en"
												type="text"
												name="description"
												value={formData?.description?.en}
												onChange={handleInputChange}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-4">
						<div className="card">
							<div className="card-body">
								<div className="form-item">
									<input type="file" onChange={handlePicture} id="image" />
									<img
										src={`${process.env.REACT_APP_MAIN_URL}${image}`}
										alt="avatar"
										className="imagePreview"
									/>
								</div>
							</div>
						</div>
						<div className="form-item mt-3">
							<button className="bt bt-success btn-block">ارسال</button>
						</div>
					</div>
				</div>
			</form>
		</section>
	);
};

export default TestimonialShow;
