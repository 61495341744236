import React, { useEffect, useState } from "react";
import axios from "axios";
import cookie from "js-cookie";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Loader from "../../components/shared/Loader";
import { updateMeta } from "../../store/actions/metaAction";

const LinksCreate = () => {
	const auth = useSelector(state => state.auth.user);
	const history = useHistory();
	const [title, setTitle] = useState("");
	const [titleEn, setTitleEn] = useState("");
	const [link, setLink] = useState("");
	const [loading, setLoading] = useState(true);
	const dispatch = useDispatch();

	let editor = null;

	useEffect(() => {
		onStart();
	}, []);

	const onStart = () => {
		setLoading(false);
		dispatch(
			updateMeta({
				title: "اضافة رابط جديد",
				breadcrumb: "الروابط",
				button: {
					show: false,
					text: null,
					link: null,
				},
			})
		);
	};

	const handleSubmit = e => {
		setLoading(true);
		e.preventDefault();
		if (!title || !link) {
			toast.error("برجاء ملئ كل الحقول!");
			setLoading(true);
			return;
		}

		const data = {
			title: {
				ar: title,
				en: titleEn,
			},
			link,
		};

		axios
			.post(
				`${process.env.REACT_APP_API_URL}/posts`,
				{
					admin_id: auth.id,
					category_id: 39,
					data: data,
				},
				{
					headers: {
						Authorization: `Bearer ${cookie.get("token")}`,
						Accept: "application/json",
					},
				}
			)
			.then(function (response) {
				history.push("/footerLinks/index");
				toast.success("تم اضافة الرابط بنجاح!");
				setLoading(false);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة مره اخرى!");
				setLoading(false);
			});
	};

	return (
		<section>
			{loading && <Loader />}
			<form onSubmit={handleSubmit}>
				<div className="row">
					<div className="col-md-12">
						<div className="card">
							<div className="card-body">
								<div className="row">
									<div className="col-md-6">
										<div className="form-item">
											<label htmlFor="title">العنوان</label>
											<input
												type="text"
												id="title"
												required
												onChange={e => setTitle(e.target.value)}
											/>
										</div>
										<div className="form-item">
											<label htmlFor="title">العنوان بالانجليزيه</label>
											<input
												type="text"
												id="title"
												required
												onChange={e => setTitleEn(e.target.value)}
											/>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-item">
											<label htmlFor="title">الرابط</label>
											<input
												type="url"
												id="link"
												required
												onChange={e => setLink(e.target.value)}
											/>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-item mt-3">
											<button className="bt bt-success btn-block">ارسال</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		</section>
	);
};

export default LinksCreate;
