import React, { useEffect, useState } from "react";
import axios from "axios";
import cookie from "js-cookie";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import userPlaceholder from "../../assets/images/placeholder.jpg";
import Loader from "../../components/shared/Loader";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { updateMeta } from "../../store/actions/metaAction";

const BlogCreate = () => {
	const auth = useSelector(state => state.auth.user);
	const history = useHistory();
	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");
	const [picture, setPicture] = useState(userPlaceholder);
	const [loading, setLoading] = useState(true);
	const [categories, setCategories] = useState([]);
	const [selectedCategory, setSelectedCategory] = useState(0);
	const dispatch = useDispatch();

	let editor = null;

	useEffect(() => {
		onStart();
	}, []);

	const onStart = () => {
		dispatch(
			updateMeta({
				title: "اضافة مقال جديد",
				breadcrumb: "المدونة",
				button: {
					show: false,
					text: null,
					link: null,
				},
			})
		);
		axios
			.get(`${process.env.REACT_APP_API_URL}/blog_categories`, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
				},
			})
			.then(function (response) {
				setCategories(response.data.data);
				setLoading(false);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة لاحقا!");
			});
	};

	const handlePicture = () => {
		const fileInput = document.querySelector("#picture");
		setPicture(URL.createObjectURL(fileInput.files[0]));
	};

	const handleSubmit = e => {
		e.preventDefault();
		if (selectedCategory == 0) {
			toast.error("برجاء اختيار التصنيف أولا!");
			return;
		}
		const picture = document.querySelector("#picture");
		if (picture.files[0]) {
			setLoading(true);
			const data = new FormData();
			data.append("file", picture.files[0]);
			axios
				.post(`${process.env.REACT_APP_API_URL}/upload`, data, {
					headers: {
						Authorization: `Bearer ${cookie.get("token")}`,
						Accept: "application/json",
						"Content-Type": "multipart/form-data",
					},
				})
				.then(response => {
					axios
						.post(
							`${process.env.REACT_APP_API_URL}/blogs`,
							{
								title: title,
								description: description,
								admin_id: auth.id,
								category_id: selectedCategory,
								image: response.data.data.name,
							},
							{
								headers: {
									Authorization: `Bearer ${cookie.get("token")}`,
									Accept: "application/json",
								},
							}
						)
						.then(function (response) {
							history.push("/blog/index");
							toast.success("تم اضافة المقالة بنجاح!");
							setLoading(false);
						})
						.catch(function (error) {
							toast.error("خطأ، برجاء المحاولة مره اخرى!");
							setLoading(false);
						});
				})
				.catch(error => {
					toast.error("خطأ في رفع الصورة، برجاء المحاولة مرة أخرى!");
					setLoading(false);
				});
		} else {
			toast.error("برجاء اختيار الصورة أولا!");
		}
	};

	return (
		<section>
			{loading && <Loader />}
			<form onSubmit={handleSubmit}>
				<div className="row">
					<div className="col-md-9">
						<div className="card">
							<div className="card-body">
								<div className="form-item">
									<label htmlFor="category">التصنيف</label>
									<select
										id="category"
										onChange={e => setSelectedCategory(e.target.value)}
									>
										<option value="0">اختر التصنيف</option>
										{categories.map(category => (
											<option value={category.id} key={category.id}>
												{category.name}
											</option>
										))}
									</select>
								</div>
								<div className="form-item">
									<label htmlFor="title">العنوان</label>
									<input
										type="text"
										id="title"
										required
										onChange={e => setTitle(e.target.value)}
									/>
								</div>
								<div className="form-item">
									<label htmlFor="description">النص</label>
									<CKEditor
										onReady={editor => {
											editor.ui
												.getEditableElement()
												.parentElement.insertBefore(
													editor.ui.view.toolbar.element,
													editor.ui.getEditableElement()
												);
											editor = editor;
										}}
										onError={({ willEditorRestart }) => {
											if (willEditorRestart) {
												editor.ui.view.toolbar.element.remove();
											}
										}}
										onChange={(event, editor) => {
											const data = editor.getData();
											setDescription(data);
										}}
										editor={DecoupledEditor}
										config={{
											contentsLangDirection: "rtl",
											language: "ar",
										}}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-3">
						<div className="card">
							<div className="card-body">
								<div className="form-item">
									<input type="file" onChange={handlePicture} id="picture" />
									<img src={picture} alt="avatar" className="imagePreview" />
								</div>
							</div>
						</div>
						<div className="form-item mt-3">
							<button className="bt bt-success btn-block">ارسال</button>
						</div>
					</div>
				</div>
			</form>
		</section>
	);
};

export default BlogCreate;
